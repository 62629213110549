export const ADD_USER = 'ADD_USER';
export const USER_LOADING = 'USER_LOADING';
export const USER_FAILED = 'USER_FAILED';
export const ADD_ALL_USERS = 'ADD_ALL_USERS';
export const USERS_FAILED = 'USERS_FAILED';
export const USERS_LOADING = 'USERS_LOADING';
export const USER_SERVER_ERROR = 'USER_SERVER_ERROR';
export const USER_MISC_ERROR_FIN = 'USER_MISC_ERROR_FIN';

export const ADD_EVENTS = 'ADD_EVENTS';
export const EVENTS_LOADING = 'EVENTS_LOADING';
export const EVENTS_FAILED = 'EVENTS_FAILED';
export const EVENT_SERVER_ERROR = 'EVENT_SERVER_ERROR';
export const EVENT_MISC_ERROR_FIN = 'EVENT_MISC_ERROR_FIN';

export const ADD_PROJECTS = 'ADD_PROJECTS';
export const PROJECTS_LOADING = 'PROJECTS_LOADING';
export const PROJECTS_FAILED = 'PROJECTS_FAILED';
export const PROJECT_SERVER_ERROR = 'PROJECT_SERVER_ERROR';
export const PROJECT_MISC_ERROR_FIN = 'PROJECT_MISC_ERROR_FIN';

export const ADD_RESOURCES = 'ADD_RESOURCES';
export const RESOURCES_LOADING = 'RESOURCES_LOADING';
export const RESOURCES_FAILED = 'RESOURCES_FAILED';
export const RESOURCE_SERVER_ERROR = 'RESOURCE_SERVER_ERROR';
export const RESOURCE_MISC_ERROR_FIN = 'RESOURCE_MISC_ERROR_FIN';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FIN = 'REGISTER_FIN';
export const NEW_REG = 'NEW_REG';
export const NEW_REG_DONE = 'NEW_REG_DONE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_FAILURE_FIN = 'LOGIN_FAILURE_FIN';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
